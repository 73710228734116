// import React from "react"
// import Title from "../Title"
// import styles from "../../css/services.module.css"
// import team from "../../constants/team"
// import Image from "gatsby-image"
// import { useStaticQuery, graphql } from "gatsby"

// export const Team = () => {
//   return (
//     <section className={styles.services}>
//       <Title title="the" subtitle="crew" />
//       <div className={styles.center}>
//         {team.map((item, index) => {
//           return (
//             <article key={index} className={styles.service}>
//               <h4>{item.title}</h4>
//               <p>{item.text}</p>
//             </article>
//           )
//         })}
//       </div>
//     </section>
//   )
// }

import React from "react"
import Title from "../Title"
import styles from "../../css/services.module.css"
import { useStaticQuery, graphql } from "gatsby"

const getTeam = graphql`
  query {
    teamMembers: allContentfulTeam {
      edges {
        node {
          contentful_id
          name
          description
          image {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

export const Team = () => {
  const response = useStaticQuery(getTeam)
  const team = response.teamMembers.edges

  return (
    <section className={styles.services}>
      <Title title="the" subtitle="crew" />
      <div className={styles.center}>
        {team.map((item, index) => {
          return (
            <article key={index} className={styles.service}>
              <h4>{item.node.name}</h4>
              <p>{item.node.description}</p>
            </article>
          )
        })}
      </div>
    </section>
  )
}
