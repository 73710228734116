import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import About from "../components/Home/About"
import { Team } from "../components/Home/Team"
import StyledHero from "../components/StyledHero"
import { graphql } from "gatsby"
import { FeaturedEpisodes } from "../components/Home/FeaturedEpisodes"
import SEO from "../components/SEO"

export default ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <StyledHero home="true">
      <Banner
        title="surf podcast"
        info="Everyday joes from NJ, talkin about all things&nbsp;surf"
      >
        <Link fade to="/episodes" className="btn-white">
          Watch Episodes
        </Link>
      </Banner>
    </StyledHero>
    <About />
    <Team />
    <FeaturedEpisodes />
  </Layout>
)

export const query = graphql`
  query {
    defaultBcg: file(relativePath: { eq: "defaultBcg.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
