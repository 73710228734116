import React from "react"
import Title from "../Title"
import styles from "../../css/about.module.css"
// import img from "../../images/defaultBcg.jpeg"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"

const getAbout = graphql`
  query aboutImage {
    aboutImage: file(relativePath: { eq: "surfPodcast.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const About = () => {
  const { aboutImage } = useStaticQuery(getAbout)

  return (
    <section className={styles.about}>
      <Title title="yewww" subtitle="toob" />
      <div className={styles.aboutCenter}>
        <article className={styles.aboutImg}>
          <div className={styles.imgContainer}>
            {/* <img src={img} alt="about company" /> */}
            <Img
              fluid={aboutImage.childImageSharp.fluid}
              alt="awesome landscape"
            />
          </div>
        </article>
        <article className={styles.aboutInfo}>
          <h4>NJ/NY Surf Podcast</h4>
          <p>
            Yewwwtoob is your daily dose of full-spectrum surf conversation.
          </p>
          <p>
            We're a bunch of regular doods from the NJ/NY area who can't really
            hang out with other people because...well...they don't speak surf.
          </p>
          <Link to="/contact" className="btn-primary">
            hit us up
          </Link>
        </article>
      </div>
    </section>
  )
}

export default About
